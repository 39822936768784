<template>
  <div class="holder">
    <div class="title">Please note:</div>
    <ul>
      <li>We are unable to accept American Express card payments</li>
      <li>
        If you would like to make a payment of over &pound;500, please
        <a href="https://dataenergy.co.uk/payment-methods/" target="_blank"
          >visit our residents hub for more payment options</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PaymentNotes"
};
</script>

<style lang="scss" scoped>
.holder {
  margin-top: 15px;
  text-align: left;
  font-size: 0.8rem;
  color: #777;

  .title {
    font-weight: bold;
  }
}
</style>
